
import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import BtnRadio from "@/components/scope/btn-radio.vue";
import { emptyToLine, splitThousands } from "@/utils/common";
import { Table } from "@/utils/decorator";
import dayjs from "dayjs";
import echarts from "echarts";

const finance = namespace("finance");

const management = namespace("management")

@Component({
  components: { BtnRadio }
})
@Table("loadListData")
export default class ReportOutputValue extends Vue {
  @finance.Action getGrossProfitList;
  @finance.Action getGrossProfitReport;
  @management.Action getStoreList;
  dayjs = dayjs;
  emptyToLine = emptyToLine;
  splitThousands = splitThousands;
  chartFormData: any = {
    timeType: "1",
    selectedDate: [],
  };

  storeCode:any = "";
  storeName: any = "";
  listFormData: any = {
    statisticMethod: "1",
    statisticDate: "",
  };

  storeList: any = [];
  searchData: any = {};
  selLoading: boolean = false;

  nowDate: any = dayjs();
  tableData: any = [];
  grossProfitListData: any = [];
  created() {
    this.init();
  }
  myChart: any = {};
  mounted() {
    // 基于准备好的dom，初始化echarts实例
    this.myChart = echarts.init(this.$refs.outputPie);
  }
  init() {
    this.chartFormData.selectedDate = [
      this.nowDate.subtract(7, "d").format("YYYYMMDD"),
      this.nowDate.subtract(1, "d").format("YYYYMMDD")
    ];
    this.listFormData.statisticDate = this.nowDate.subtract(1, "d").format("YYYYMMDD");

    this.selLoading = true;
    this.getStoreList({
      pageNum: 1,
      pageSize: 50,
      storeName: "",
      storeTypeList: ["3", "8", "7","9"]
    }).then(res => {
      this.selLoading = false;
      this.storeList = res.data.list || [];
      if(this.storeList && this.storeList.length > 0){
        this.storeCode =  this.storeList[0].storeCode
        this.storeName =  this.storeList[0].storeName
      }
      this.loadChartData();
      this.loadListData();
    })
  }
  handleStoreChange(e){
    const storeInfo = this.storeList.filter(item=> item.storeCode === e)
    if(storeInfo){
      this.storeName = storeInfo[0].storeName
    }
    this.loadChartData();
    this.loadListData();
  }
  draw(serverData?) {
    let showItemNum = 0;
    if (this.chartFormData.timeType === "1") {
      showItemNum = 7;
    } else if (this.chartFormData.timeType === "2") {
      showItemNum = 30;
    } else {
      showItemNum = 12;
    }
    let xData = [];
    let data = [];
    let nowDate = this.nowDate;
    for (let i = showItemNum; i > 0; i--) {
      let tempDate = nowDate.subtract(i, "d");
      let tempDateMonth = nowDate.subtract(i, "M");
      if (this.chartFormData.timeType === "3") {
        xData.push(tempDateMonth.format("YYYY-MM"));
        let findData = serverData.data.yearTrend.find(item => item.title === tempDateMonth.format("YYYYMM"));
        if (findData) {
          data.push(Number(findData.grossProfitAmount));
        } else {
          data.push(0);
        }
      } else {
        xData.push(tempDate.format("MM-DD"));
        let findData = serverData.data.monthTrend.find(item => item.title === tempDate.format("YYYYMMDD"));
        if (findData) {
          data.push(Number(findData.grossProfitAmount));
        } else {
          data.push(0);
        }
      }
    }
    let options = {
      xAxis: {
        type: "category",
        data: xData,
        axisLabel: {
          textStyle: {
            //文字样式
            color: "#8C8C8C",
            fontSize: "12"
          },
          rotate: this.chartFormData.timeType === "2" ? 40 : 0 //倾斜度可以根据需求设置
        },
        axisLine: {
          lineStyle: {
            color: "#8C8C8C"
          }
        }
      },
      tooltip: {
        trigger: "axis",
        formatter: params => {
          return `${params[0].name}<br/>${this.$t("finance.gross-margin")}: ${
            this.$localMark.currencySymbol + splitThousands(params[0].value)
          }`;
        }
      },
      legend: {
        data: [this.$t("finance.gross-margin")],
        right: 10
      },
      grid: {
        x: 100,
        y: 45,
        x2: 100,
        y2: 40,
        borderWidth: 1
      },
      yAxis: {
        name: this.$t("base.unit") + "：" + this.$t("finance.yuan"),
        type: "value",
        axisLabel: {
          textStyle: {
            //文字样式
            color: "#8C8C8C",
            fontSize: "12"
          }
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        }
      },
      color: ["#FF9900"],
      series: [
        {
          name: this.$t("finance.gross-margin"),
          data: data,
          type: "line"
        }
      ]
    };
    // 绘制图表
    this.myChart.setOption(options);
  }
  grossProfitData: any = {};
  @Table.LoadDataMethod()
  loadListData() {
    let params = Object.assign({}, this.listFormData, {
      pageSize: this.mix_pageSize,
      pageNum: this.mix_pageNum,
      storeCode: this.storeCode
    });
    return this.getGrossProfitList(params).then(data => {
      this.grossProfitData = data.data;
      this.grossProfitListData = data.data.list;
      this.grossProfitListData.map(item => {
        item.storeName = this.storeName;
        return item;
      })
      return data;
    });
  }
  handleTableDateTypeChange(val) {
    if (val === "1") {
      this.listFormData.statisticDate = this.nowDate.subtract(1, "d").format("YYYYMMDD");
    } else {
      this.listFormData.statisticDate = this.nowDate.subtract(1, "M").format("YYYYMM");
    }
    this.loadListData();
  }
  handleListDateChange() {
    this.loadListData();
  }
  loadChartData() {
    this.getGrossProfitReport({
      beginTime: this.chartFormData.selectedDate[0],
      endTime: this.chartFormData.selectedDate[1],
      storeCode: this.storeCode
    }).then(data => {
      console.log(data);
      this.draw(data);
    });
  }
  handleLinkView(row) {
    let { href } = this.$router.resolve({
      path: `/finance/collection-order-view/${row.receiptNo}`
    });
    window.open(href, "_blank");
  }
  handleServiceOrderView(row) {
    let { href } = this.$router.resolve({
      path: `/service/view/${row.serviceOrderNo}?tenantCode=${row.tenantCode}`
    });
    window.open(href, "_blank");
  }
  handleDateChange(val) {
    switch (val) {
      case "1":
        this.chartFormData.selectedDate = [
          this.nowDate.subtract(7, "d").format("YYYYMMDD"),
          this.nowDate.subtract(1, "d").format("YYYYMMDD")
        ];
        break;
      case "2":
        this.chartFormData.selectedDate = [
          this.nowDate.subtract(30, "d").format("YYYYMMDD"),
          this.nowDate.subtract(1, "d").format("YYYYMMDD")
        ];
        break;
      default:
        this.chartFormData.selectedDate = [
          this.nowDate.subtract(365, "d").format("YYYYMMDD"),
          this.nowDate.subtract(1, "d").format("YYYYMMDD")
        ];
    }
    this.loadChartData();
  }

  searchStore(val = "") {
    this.selLoading = true;
    this.getStoreList({
      pageNum: 1,
      pageSize: 50,
      storeName: val,
      storeTypeList: ["3", "8", "7","9"]
    }).then(res => {
      this.selLoading = false;
      this.storeList = res.data.list || [];
    })
  }
}
